// Shared variables.

:root {
  // Utilities.
  --fast: 0.2s;
  --slow: 0.4s;
}

// TODO: switch primeng variables to use project variables.

// Primeng variables saga-blue.

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: var(--primary-50);
  --primary-color-text: #ffffff;
  --font-family: var(--base-font-family);
}

$desktop-width: 960px;

.bg-theme {
  background-color: var(--bg-theme);
}

.text-theme {
  color: var(--text-color-theme);
}

.shadow-theme {
  box-shadow: var(--shadow-box-shadow);
  border: var(--shadow-border);
}

.section-header {
  font-size: var(--section-header-font-size);
  line-height: var(--section-header-line-height);
  text-align: var(--section-header-text-align);
}

* {
  font-family: 'Myriad Pro', sans-serif;
}

.svg {
  height: 1em;
  width: 1em;
  background-color: currentColor;
}

.svg-bus-ticket {
  -webkit-mask: url(../assets/icons/009-bus-ticket.svg) no-repeat center;
          mask: url(../assets/icons/009-bus-ticket.svg) no-repeat center;
}

.icon {
  background-image: url(../assets/local/ikona.png);
  background-repeat: no-repeat;
  background-position: center;
}

.bus-ticket {
  -webkit-mask: url(../assets/images/bus-ticket-edit.svg) no-repeat center;
          mask: url(../assets/images/bus-ticket-edit.svg) no-repeat center;
  -webkit-mask-size: 101% 160%;
          mask-size: 101% 160%;
}

/* -------------------------------- DataView -------------------------------- */

.p-dataview-content {
  padding-top: 2rem !important;
}

.p-dataview-grid {
  .p-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media only screen and (min-width: 1000px) {
    .p-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (min-width: 1300px) {
    .p-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

/* --------------------------------- Dialog --------------------------------- */

.p-dialog {
  --border-radius: 10px;
  width: 350px;
  border-radius: var(--border-radius) !important;
  div {
    border-radius: 0;
  }
  .p-dialog-content {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .p-dialog-header-icon {
    border-radius: 50% !important;
  }
  .p-dialog-footer {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .p-dialog-header {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
}

.p-dialog .p-dialog-content {
  padding: 0 1.15rem 1.5rem 1.15rem !important;
}

.p-dialog .p-dialog-header {
  padding-left: 1.15rem !important;
  padding-right: 1.15rem !important;
}

p-dialog.cart-dialog {
  .p-dialog {
    margin-top: 60px;
  }
}

p-dialog.customer-info-dialog {
  .p-dialog {
    width: 550px !important;
  }
}

p-dialog.customer-info-cart-dialog {
  .p-dialog {
    width: 550px !important;
  }
}

.account-validate-dialog-wrapper {
  .p-dialog-header {
    justify-content: center !important;
  }
}

/* -------------------------------- Dropdown -------------------------------- */

.p-dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: keep-all;
  //max-width: 300px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-radius: 0 !important;
}

.contact-form-dropdown {
  // .p-dropdown-items-wrapper {
  .p-dropdown-item {
    max-width: 100% !important;
  }
  // }
}

.outlet {
  .p-dropdown-item {
    max-width: 100% !important;
  }
}

.tickets-type-card-dropdown {
  .p-dropdown-item {
    max-width: 100%;
  }
}

.jpk-transport-authority-dropdown-wrapper {
  .p-dropdown {
    width: 220px !important;
  }
}

.invoice-mask-dropdown {
  .p-dropdown-item {
    max-width: 100% !important;
  }
  .p-dropdown {
    width: 100% !important;
  }
}

.p-dropdown-item-group {
  padding: 0 !important;
}

/* -------------------------------- InputMask ------------------------------- */

.p-inputmask {
  width: 100%;
}

/* ---------------------------------- Menu ---------------------------------- */

.p-menuitem-link-active {
  background-color: var(--primary-color) !important;
  & span {
    color: var(--primary-10) !important;
  }
}

/* ---------------------------------- Modal --------------------------------- */

/* Fight primeng modal adding p-overflow-hidden class to body to hide scroll. */

body.p-overflow-hidden {
  overflow: auto !important;
}

/* ---------------------------------- Toast --------------------------------- */

.p-toast {
  width: 20rem;
}

@media screen and (min-width: 769px) {
  .p-toast {
    width: 25rem;
  }
}

/* -------------------------------- Calendar -------------------------------- */

// p-calendar {

//     //width: 100% !important;

//     span:first-child {

//         width: 100% !important;

//     }

// }

/* ---------------------------------- Misc ---------------------------------- */

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-menuitem-link,
.p-button-icon-only,
.p-tabview-nav-link,
.p-ripple,
.p-button {
  box-shadow: none !important;
}

.text-xxs {
  font-size: 0.625rem;
}

.text-active {
  color: var(--text-active);
}

.primary-font-color {
  color: var(--active-font-color);
}

// Custom component styles.

app-navigation {
  --transition: var(--slow);
  .toggle {
    padding: 1rem;
  }
  .mobile--router {
    font-size: 1.5rem;
  }
  .mobile--item--link {
    display: flex;
    padding: 1rem;
    &:hover {
      background: var(--primary-color);
      color: hsl(0, 0%, 100%);
      transition: var(--fast);
    }
    &:after {
      transition: width var(--fast) ease 0s, left var(--fast) ease 0s;
    }
  }
}

@media only screen and (min-width: $desktop-width) {
  app-navigation {
    .desktop {
      grid-template-columns: var(--navigation-desktop-grid);
      grid-column-gap: 30px;
    }
    .desktop--router {
      font-size: 1.25rem;
    }
    .desktop--item:not(:last-of-type) {
      margin-right: 1rem;
    }
    .desktop--item--link--te xt {
      position: relative;
      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: var(--primary-color);
        transition: width var(--fast) ease 0s, left var(--fast) ease 0s;
        width: 0;
      }
    }
    .desktop--item--link:hover > .desktop--item--link--text {
      position: relative;
      transition: var(--fast);
      color: var(--active-font-color);
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }
}

.theme-button {
  padding: 0.5rem 1rem;
  color: var(--button-text-color);
  background: var(--button-bg);
  border-radius: 0.75rem;
  :hover {
    transition: 0.2s;
    background-color: var(--button-bg-hover);
    color: var(--button-text-color-hover);
  }
}

@media only screen and (min-width: $desktop-width) {
  .intro {
    grid-template-columns: 450px 1fr;
  }
}

@media only screen and (min-width: $desktop-width) {
  .faq {
    grid-template-columns: 500px 1fr;
  }
}

@media only screen and (min-width: $desktop-width) {
  .tos-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
            'Text Text Text Text Text Text Text .'
            '. . . . . . Picture Picture';
  }

  .text {
    grid-area: Text;
  }

  .picture {
    grid-area: Picture;
  }
}

.search-ticket {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  top: -47px;
  background: var(--search-ticket-bg);
  font-size: var(--search-ticket-font-size);
  color: var(--search-ticket-text-color);
}

.search-ticket-text {
  font-weight: var(--search-ticket-text-font-weight);
}

.p-dropdown.p-component {
  width: 100%;
}

.p-inputtext.p-component, .p-dropdown.p-component, .p-button.p-component {
  border-radius: 0.5rem;
}

.contact-box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (min-width: $desktop-width) {
  .contact-form-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
  }

  .man {
    grid-area: 1 / 3 / 3 / 5;
  }

  .hands {
    grid-area: 2 / 1 / 4 / 4;
  }
}

.increment-wrapper {
  .p-inputnumber-input {
    padding: 0.125rem;
  }
}
